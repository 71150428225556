import { Theme, useMediaQuery } from '@mui/material';
import { Account } from 'api';
import { getToken, setToken } from 'config';
import { OptionsObject, ProviderContext, useSnackbar } from 'notistack';
import React, { createContext, Dispatch, ReactNode, useCallback, useContext, useReducer } from 'react';

const CurrentUserContext = createContext<Account>({});
const CurrentUserDispatchContext = createContext<Dispatch<Account>>((_: Account) => null);

export function CurrentUserProvider({ children }: { children?: ReactNode | undefined }): React.ReactElement {
  let currentUser: Account = {};

  const [state, dispatch] = useReducer((state: Account, action: Account) => {
    currentUser = action;
    return currentUser;
  }, currentUser);

  return (
    <CurrentUserContext.Provider value={state}>
      <CurrentUserDispatchContext.Provider value={dispatch}>{children}</CurrentUserDispatchContext.Provider>
    </CurrentUserContext.Provider>
  );
}

export function useCurrentUser(): Account {
  return useContext(CurrentUserContext);
}

export function useSetCurrentUser(): Dispatch<Account> {
  return useContext(CurrentUserDispatchContext);
}

export function useCurrentUserState(): [Account, Dispatch<Account>] {
  return [useCurrentUser(), useSetCurrentUser()];
}

// Access Token
export function useAccessTokenState(): [string | undefined, Dispatch<string | undefined>] {
  return useReducer((currentToken: string | undefined, newToken: string | undefined) => {
    setToken(newToken);
    return newToken;
  }, getToken());
}

export function useAccessToken(): string | undefined {
  return useAccessTokenState()[0];
}

export function useIsMobileSize(): boolean {
  return useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
}

export const phoneMediaQuery = '@media (max-width: 450px)';
export function useIsPhoneSize(): boolean {
  return useMediaQuery(() => phoneMediaQuery);
}

// Create a trigger can be set as a dependency and a function that fires the trigger when called
export function useTrigger(): [number, () => void] {
  return useReducer((state) => state + 1, 0);
}

export interface RequestErrorProps extends OptionsObject {
  message?: string;
}

export type RequestErrorCallback = (snackbar: ProviderContext, error: unknown) => void;
export type RequestErrorHandler = string | RequestErrorProps | RequestErrorCallback;
export type RequestErrorHandlers = Record<number, RequestErrorHandler> & { default?: RequestErrorHandler };

const defaultRequestErrorHandler: RequestErrorProps = { message: 'Error processing request', variant: 'error' };

export type ErrorHandler = <T>(func: () => Promise<T>, handlers?: RequestErrorHandlers) => Promise<T | undefined>;

export function useErrorHandler(): ErrorHandler {
  const snackbar = useSnackbar();

  return useCallback(
    async function <T>(func: () => Promise<T>, handlers: RequestErrorHandlers = {}) {
      try {
        return await func();
      } catch (error) {
        let handler: RequestErrorHandler | undefined;

        if (error instanceof Response) {
          handler = handlers[error.status];
        }

        handler ||= handlers['default'];
        handler ||= defaultRequestErrorHandler;

        switch (typeof handler) {
          case 'string':
            snackbar.enqueueSnackbar(handler, defaultRequestErrorHandler);
            break;
          case 'function':
            handler(snackbar, error);
            break;
          default:
            const { message = defaultRequestErrorHandler.message, ...snackbarProps } = handler;
            snackbar.enqueueSnackbar(message, snackbarProps);
        }
      }
    },
    [snackbar]
  );
}
