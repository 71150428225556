import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, TextField } from '@mui/material';
import { UsersApi } from 'api';
import { AppContainer } from 'components/AppContainer';
import { PaddedBox } from 'components/PaddedBox';
import { apiConfig } from 'config';
import { useErrorHandler } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { handleSubmit } from 'utils';

export function CreateAccountView(): React.ReactElement {
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const usersApi = new UsersApi(apiConfig());

  const onSubmit = handleSubmit(() =>
    handleError(
      async () => {
        if (password !== confirmPassword) {
          snackbar.enqueueSnackbar('Passwords do not match', { variant: 'warning' });
          return;
        }

        await usersApi.createUser({ createUserProps: { email, password, displayName } });
        navigate(`/login${location.search + location.hash}`);
      },
      { default: 'Error creating account' }
    )
  );

  return (
    <AppContainer maxWidth="xs" sx={{ '&.MuiContainer-root': { height: '100%' } }}>
      <Grid container direction="column" justifyContent="center" sx={{ minHeight: '100%' }}>
        <Grid item>
          <PaddedBox>
            <form noValidate target="" onSubmit={onSubmit}>
              <Card elevation={5}>
                <CardHeader title="Create Account" />
                <Divider />
                <CardContent>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <TextField
                        label="Username"
                        type="email"
                        className="w-100"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        label="Display Name"
                        className="w-100"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        label="Password"
                        type="password"
                        className="w-100"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        label="Confirm Password"
                        type="password"
                        className="w-100"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </Grid>
                    <Grid item alignSelf="flex-end">
                      <Link to={`/login${location.search + location.hash}`}>Already have an account?</Link>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Button variant="contained" type="submit">
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </form>
          </PaddedBox>
        </Grid>
      </Grid>
    </AppContainer>
  );
}
