import { Grid, Typography } from '@mui/material';
import { AppContainer } from 'components/AppContainer';
import { PaddedBox } from 'components/PaddedBox';
import React from 'react';

export function SupportView(): React.ReactElement {
  return (
    <AppContainer maxWidth="md" sx={{ '&.MuiContainer-root': { height: '100%' } }}>
      <PaddedBox>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Cordial Support</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              <b>Email:</b> joshraker+support@gmail.com
            </Typography>
          </Grid>
        </Grid>
      </PaddedBox>
    </AppContainer>
  );
}
