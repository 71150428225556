import { Button, Grid, Typography } from '@mui/material';
import { UsersApi } from 'api';
import { AppContainer } from 'components/AppContainer';
import { disableNotifications, enableNotifications } from 'components/RequestNotificationsDialog';
import { Hide } from 'components/visibility/Hide';
import { Show } from 'components/visibility/Show';
import { apiConfig, getNotificationsRequested } from 'config';
import { useCurrentUserState, useErrorHandler, useTrigger } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { abortEffect, notificationsSupported } from 'utils';

export function AccountView(): React.ReactElement {
  const [showNotificationSettings, setShowNotificationSettings] = useState(false);
  const [notificationSettingTrigger, triggerNotificationSetting] = useTrigger();
  const [currentUser, setCurrentUser] = useCurrentUserState();
  const snackbar = useSnackbar();
  const handleError = useErrorHandler();
  const usersApi = new UsersApi(apiConfig());
  const notificationsEnabled = useMemo(
    () => getNotificationsRequested() && 'Notification' in window && Notification.permission === 'granted',
    [notificationSettingTrigger]
  );

  useEffect(() => {
    notificationsSupported().then(setShowNotificationSettings);

    return abortEffect((signal) => {
      handleError(async () => {
        setCurrentUser(await usersApi.getMyAccount({ signal }));
      });
    });
  }, []);

  return (
    <AppContainer>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h2" align="center">
            Account
          </Typography>
        </Grid>
        {[
          { label: 'Display Name', value: currentUser.displayName },
          { label: 'Username', value: currentUser.email }
        ].map((e, i) => (
          <Grid item key={i}>
            <Typography variant="h5" align="center">
              <b>{e.label}:</b> {e.value}
            </Typography>
          </Grid>
        ))}
        <Show when={showNotificationSettings}>
          <Grid item>
            <Grid container direction="column" spacing={1} alignItems="center">
              <Grid item>
                <Button
                  variant="contained"
                  size="large"
                  onClick={async () => {
                    if (notificationsEnabled) {
                      await disableNotifications();
                      snackbar.enqueueSnackbar('Notifications disabled', { variant: 'success' });
                    } else {
                      await enableNotifications(snackbar, handleError);
                    }

                    triggerNotificationSetting();
                  }}
                >
                  {notificationsEnabled ? 'Disable Notifications' : 'Enable Notifications'}
                </Button>
              </Grid>
              <Hide when={notificationsEnabled}>
                <Grid item textAlign="center">
                  (Requires browser notifications permission)
                </Grid>
              </Hide>
            </Grid>
          </Grid>
        </Show>
      </Grid>
    </AppContainer>
  );
}
