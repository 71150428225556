import { Grid, Typography } from '@mui/material';
import { AppContainer } from 'components/AppContainer';
import { PaddedBox } from 'components/PaddedBox';
import React from 'react';

export function PrivacyPolicyView(): React.ReactElement {
  return (
    <AppContainer maxWidth="md" sx={{ '&.MuiContainer-root': { height: '100%' } }}>
      <PaddedBox>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3">Cordial Privacy Policy</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">This application does not collect any personal information.</Typography>
          </Grid>
        </Grid>
      </PaddedBox>
    </AppContainer>
  );
}
