/* tslint:disable */
/* eslint-disable */
/**
 * Cordial
 * A Wordle game you play with friends!
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotificationsSubscribeKeys,
    NotificationsSubscribeKeysFromJSON,
    NotificationsSubscribeKeysFromJSONTyped,
    NotificationsSubscribeKeysToJSON,
} from './NotificationsSubscribeKeys';

/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    pushToken?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    endpoint?: string;
    /**
     * 
     * @type {NotificationsSubscribeKeys}
     * @memberof Subscription
     */
    keys?: NotificationsSubscribeKeys;
}

export function SubscriptionFromJSON(json: any): Subscription {
    return SubscriptionFromJSONTyped(json, false);
}

export function SubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pushToken': !exists(json, 'pushToken') ? undefined : json['pushToken'],
        'endpoint': !exists(json, 'endpoint') ? undefined : json['endpoint'],
        'keys': !exists(json, 'keys') ? undefined : NotificationsSubscribeKeysFromJSON(json['keys']),
    };
}

export function SubscriptionToJSON(value?: Subscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pushToken': value.pushToken,
        'endpoint': value.endpoint,
        'keys': NotificationsSubscribeKeysToJSON(value.keys),
    };
}

