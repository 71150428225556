import { Button, Grid, Typography } from '@mui/material';
import { GamesApi, User, UsersApi } from 'api';
import { AppContainer } from 'components/AppContainer';
import { apiConfig } from 'config';
import { useErrorHandler } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { abortEffect } from 'utils';

export function UserView(): React.ReactElement {
  const [user, setUser] = useState<User | null>();
  const params = useParams();
  const navigate = useNavigate();
  const usersApi = new UsersApi(apiConfig());
  const gamesApi = new GamesApi(apiConfig());
  const handleError = useErrorHandler();
  const startGame = useCallback(() => {
    handleError(
      async () => {
        if (user?.id == null) {
          return;
        }

        const game = await gamesApi.createGame({ createGameProps: { acceptorId: user?.id } });
        navigate(`/games/${game.id}`);
      },
      { default: 'Error creating game' }
    );
  }, [user]);

  useEffect(() => {
    return abortEffect((signal) => {
      handleError(
        async () => {
          if (params.id == null) {
            return;
          }

          setUser(await usersApi.getUser({ id: params.id }, { signal }));
        },
        {
          404: 'User not found',
          default: 'Error retrieving user'
        }
      );
    });
  }, [params]);

  return (
    <AppContainer>
      <Grid container direction="column" spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="h2">{user?.displayName}</Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" size="large" onClick={startGame}>
            Start Game
          </Button>
        </Grid>
      </Grid>
    </AppContainer>
  );
}
