import { Apple, PlayArrow } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, Divider, Grid } from '@mui/material';
import { DialogCloseTitle } from 'components/DialogCloseTitle';
import { setNativeAppRejected } from 'config';
import React from 'react';
import { isIOS } from 'react-device-detect';
import { closeDialog } from 'utils';

const playStoreHref = 'https://play.google.com/store/apps/details?id=com.joshraker.cordial';
const appStoreHref = 'https://testflight.apple.com/join/kxMYdscM';

export function InstallNativeDialog(dialogProps: DialogProps): React.ReactElement {
  const { onClose } = dialogProps;
  const ButtonIcon = isIOS ? Apple : PlayArrow;

  return (
    <Dialog maxWidth="sm" {...dialogProps}>
      <DialogCloseTitle onClose={onClose}>Install App</DialogCloseTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="column" spacing={1} alignItems="center" textAlign="center">
          {isIOS ? (
            <>
              <Grid item>Cordial is available for iOS!</Grid>
              <Grid item>
                Unfortunately, due to some issues publishing to the App store, it&apos;s currently only available
                through TestFlight.
              </Grid>
            </>
          ) : (
            <Grid item>Cordial is available for Android on the Google Play store!</Grid>
          )}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={() => {
                setNativeAppRejected(true);
                closeDialog(onClose);
              }}
            >
              Stop Asking
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" component="a" href={isIOS ? appStoreHref : playStoreHref}>
              Install <ButtonIcon sx={{ ml: 1 }} />
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
