import { Backspace } from '@mui/icons-material';
import { Button, ButtonProps, Grid, GridProps } from '@mui/material';
import { Guess } from 'api';
import { useIsPhoneSize } from 'hooks';
import React, { Fragment, PropsWithChildren, useEffect, useMemo } from 'react';
import { comparisonColorMap, GuessColor } from 'themes';
import { GuessComparison } from 'utils';

interface KeyClick {
  onKeyClick?: (value: string) => void;
}

interface KeyProps extends KeyClick, ButtonProps {
  value: string;
  gridProps?: GridProps;
}

function Key(props: KeyProps): React.ReactElement {
  const { value, onKeyClick, children = value.toUpperCase(), gridProps, ...buttonProps } = props;

  return (
    <Grid item flexGrow={1} flexBasis={0} {...gridProps}>
      <Button
        variant="contained"
        onClick={(e) => {
          e.currentTarget.blur();
          onKeyClick && onKeyClick(value);
        }}
        sx={{
          width: '100%',
          height: '100%',
          minWidth: 0,
          paddingLeft: 0,
          paddingRight: 0,
          fontWeight: 'bold'
        }}
        {...buttonProps}
      >
        {children}
      </Button>
    </Grid>
  );
}

function KeyRow({ children }: PropsWithChildren<unknown>): React.ReactElement {
  return (
    <Grid item flexGrow={1}>
      <Grid container className="h-100">
        {children}
      </Grid>
    </Grid>
  );
}

function Spacer(): React.ReactElement {
  const isPhoneSize = useIsPhoneSize();
  const space = isPhoneSize ? 4 : 6;
  return <Grid item flexShrink={0} flexGrow={0} width={space} height={space} />;
}

export interface KeyboardProps extends KeyClick, GridProps {
  guesses?: Guess[] | null;
}

export function Keyboard(props: KeyboardProps): React.ReactElement {
  const { guesses, onKeyClick, ...gridProps } = props;
  const keyColorMap = useMemo(() => {
    // Find the max comparison value of each letter that's been guessed
    const comparisonMap: Record<string, GuessComparison> = {};

    for (const guess of guesses || []) {
      (guess.word || '').split('').forEach((char, index) => {
        let currentValue = comparisonMap[char];
        let newValue = (guess.comparison || [])[index];

        if (currentValue == null) {
          currentValue = GuessComparison.absent;
        }
        if (newValue == null) {
          newValue = GuessComparison.absent;
        }

        comparisonMap[char] = Math.max(currentValue, newValue);
      });
    }

    // Set the color based on the comparison value
    const colorMap: Record<string, GuessColor> = {};

    for (const key in comparisonMap) {
      colorMap[key] = comparisonColorMap[comparisonMap[key]];
    }

    return colorMap;
  }, [guesses]);

  useEffect(() => {
    if (onKeyClick == null) {
      return;
    }

    const event = 'keydown';
    const keyboardListener = (e: KeyboardEvent): void => {
      const key = (e.key || e.code).toLowerCase();

      if (/^([a-z]|backspace)$/.test(key)) {
        onKeyClick(key);
      }
    };

    // Do enter on keyup so that it won't repeat
    const enterEvent = 'keyup';
    const enterListener = (e: KeyboardEvent): void => {
      const key = (e.key || e.code).toLowerCase();

      if (key === 'enter') {
        onKeyClick(key);
      }
    };

    addEventListener(event, keyboardListener);
    addEventListener(enterEvent, enterListener);

    return () => {
      removeEventListener(event, keyboardListener);
      removeEventListener(enterEvent, enterListener);
    };
  }, [onKeyClick]);

  return (
    <Grid container direction="column" {...gridProps}>
      <KeyRow>
        <Key value="q" color={keyColorMap['q']} onKeyClick={onKeyClick} />
        {['w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'].map((letter) => (
          <Fragment key={letter}>
            <Spacer />
            <Key value={letter} color={keyColorMap[letter]} onKeyClick={onKeyClick} />
          </Fragment>
        ))}
      </KeyRow>
      <Spacer />
      <KeyRow>
        <Grid item flexGrow={0.5} flexBasis={0} />
        <Key value="a" color={keyColorMap['a']} onKeyClick={onKeyClick} />
        {['s', 'd', 'f', 'g', 'h', 'j', 'k', 'l'].map((letter) => (
          <Fragment key={letter}>
            <Spacer />
            <Key value={letter} color={keyColorMap[letter]} onKeyClick={onKeyClick} />
          </Fragment>
        ))}
        <Grid item flexGrow={0.5} flexBasis={0} />
      </KeyRow>
      <Spacer />
      <KeyRow>
        <Key value="enter" onKeyClick={onKeyClick} gridProps={{ flexGrow: 1.5 }} />
        {['z', 'x', 'c', 'v', 'b', 'n', 'm'].map((letter) => (
          <Fragment key={letter}>
            <Spacer />
            <Key value={letter} color={keyColorMap[letter]} onKeyClick={onKeyClick} />
          </Fragment>
        ))}
        <Spacer />
        <Key value="backspace" onKeyClick={onKeyClick} gridProps={{ flexGrow: 1.5 }}>
          <Backspace />
        </Key>
      </KeyRow>
    </Grid>
  );
}
