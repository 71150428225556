import { Box, Button, Grid, Typography } from '@mui/material';
import { AppContainer } from 'components/AppContainer';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export function NotFoundView(): React.ReactElement {
  const navigate = useNavigate();

  return (
    <AppContainer>
      <Box sx={{ p: 2 }}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Typography variant="h2" textAlign="center">
              Page Not Found
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" fullWidth onClick={() => navigate('/', { replace: true })}>
              Return home
            </Button>
          </Grid>
        </Grid>
      </Box>
    </AppContainer>
  );
}
