import { Game, User } from 'api';
import { FormEvent } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

export enum GuessComparison {
  absent = -1,
  present,
  correct
}

export const nativeAppAvailable = isIOS || isAndroid;

export function handleSubmit(func: (e: FormEvent) => void): (e: FormEvent) => void {
  return (e: FormEvent) => {
    e.preventDefault();
    func(e);
  };
}

// Returned inside a useEffect callback to cancel in-flight requests by
// forwarding the signal passed to the callback to requests
export function abortEffect(callback: (signal: AbortSignal) => void): () => void {
  const controller = new AbortController();

  callback(controller.signal);

  return () => {
    controller.abort();
  };
}

export function getPartner(game: Game, user: User): User | undefined {
  if (game.requesterId === user.id) {
    if (game.acceptorId === user.id) {
      return undefined;
    }

    return game.acceptor;
  }

  return game.requester;
}

export function closeDialog(onClose: DialogCloseFunction | null | undefined): void {
  onClose && onClose({}, 'backdropClick');
}

export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function getRegistration(): Promise<ServiceWorkerRegistration | undefined> {
  if ('serviceWorker' in navigator) {
    return navigator.serviceWorker.getRegistration();
  }

  return;
}

export async function getSubscription(): Promise<PushSubscription | null | undefined> {
  return await (await getRegistration())?.pushManager?.getSubscription();
}

export async function notificationsSupported(): Promise<boolean> {
  if (!('Notification' in window && 'requestPermission' in Notification && 'serviceWorker' in navigator)) {
    return false;
  }

  const registration = await navigator.serviceWorker.getRegistration();
  return registration != null && 'pushManager' in registration && 'showNotification' in registration;
}
