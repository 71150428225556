/* tslint:disable */
/* eslint-disable */
/**
 * Cordial
 * A Wordle game you play with friends!
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnsubscribeProps
 */
export interface UnsubscribeProps {
    /**
     * 
     * @type {string}
     * @memberof UnsubscribeProps
     */
    pushToken?: string;
    /**
     * 
     * @type {string}
     * @memberof UnsubscribeProps
     */
    endpoint?: string;
}

export function UnsubscribePropsFromJSON(json: any): UnsubscribeProps {
    return UnsubscribePropsFromJSONTyped(json, false);
}

export function UnsubscribePropsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnsubscribeProps {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pushToken': !exists(json, 'pushToken') ? undefined : json['pushToken'],
        'endpoint': !exists(json, 'endpoint') ? undefined : json['endpoint'],
    };
}

export function UnsubscribePropsToJSON(value?: UnsubscribeProps | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pushToken': value.pushToken,
        'endpoint': value.endpoint,
    };
}

