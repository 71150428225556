/* tslint:disable */
/* eslint-disable */
/**
 * Cordial
 * A Wordle game you play with friends!
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseModel,
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';

/**
 * 
 * @export
 * @interface Model
 */
export interface Model extends BaseModel {
    /**
     * 
     * @type {number}
     * @memberof Model
     */
    id?: number;
}

export function ModelFromJSON(json: any): Model {
    return ModelFromJSONTyped(json, false);
}

export function ModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Model {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...BaseModelFromJSONTyped(json, ignoreDiscriminator),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function ModelToJSON(value?: Model | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...BaseModelToJSON(value),
        'id': value.id,
    };
}

