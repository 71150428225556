/* tslint:disable */
/* eslint-disable */
/**
 * Cordial
 * A Wordle game you play with friends!
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Model,
    ModelFromJSON,
    ModelFromJSONTyped,
    ModelToJSON,
} from './Model';

/**
 * 
 * @export
 * @interface Discardable
 */
export interface Discardable extends Model {
    /**
     * 
     * @type {Date}
     * @memberof Discardable
     */
    discardedAt?: Date | null;
}

export function DiscardableFromJSON(json: any): Discardable {
    return DiscardableFromJSONTyped(json, false);
}

export function DiscardableFromJSONTyped(json: any, ignoreDiscriminator: boolean): Discardable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...ModelFromJSONTyped(json, ignoreDiscriminator),
        'discardedAt': !exists(json, 'discardedAt') ? undefined : (json['discardedAt'] === null ? null : new Date(json['discardedAt'])),
    };
}

export function DiscardableToJSON(value?: Discardable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...ModelToJSON(value),
        'discardedAt': value.discardedAt === undefined ? undefined : (value.discardedAt === null ? null : value.discardedAt.toISOString()),
    };
}

