import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, TextField } from '@mui/material';
import { AuthApi } from 'api';
import { AppContainer } from 'components/AppContainer';
import { PaddedBox } from 'components/PaddedBox';
import { apiConfig } from 'config';
import { useErrorHandler } from 'hooks';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { handleSubmit } from 'utils';

export interface LoginViewProps {
  onLogin: (accessToken: string | undefined) => void;
}

export function LoginView({ onLogin }: LoginViewProps): React.ReactElement {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleError = useErrorHandler();
  const authApi = new AuthApi(apiConfig());

  const onSubmit = handleSubmit(() =>
    handleError(
      async () => {
        const token = await authApi.login({ loginProps: { email: email, password: password } });
        onLogin(token.accessToken);
      },
      { 401: 'Incorrect username or password' }
    )
  );

  return (
    <AppContainer maxWidth="xs" sx={{ '&.MuiContainer-root': { height: '100%' } }}>
      <Grid container direction="column" justifyContent="center" sx={{ minHeight: '100%' }}>
        <Grid item>
          <PaddedBox>
            <form noValidate target="" onSubmit={onSubmit}>
              <Card elevation={5}>
                <CardHeader title="Cordial Login" />
                <Divider />
                <CardContent>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <TextField
                        label="Username"
                        type="email"
                        className="w-100"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        label="Password"
                        type="password"
                        className="w-100"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Grid>
                    <Grid item alignSelf="flex-end">
                      <Link to={`/create-account${location.search + location.hash}`}>Create an account</Link>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Button variant="contained" type="submit">
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </form>
          </PaddedBox>
        </Grid>
      </Grid>
    </AppContainer>
  );
}
