import { createTheme } from '@mui/material';
import { blueGrey, green, grey, yellow } from '@mui/material/colors';
import { GuessComparison } from 'utils';

export type GuessColor = 'absent' | 'present' | 'correct';

export const comparisonColorMap: Record<GuessComparison, GuessColor> = {
  [GuessComparison.absent]: 'absent',
  [GuessComparison.present]: 'present',
  [GuessComparison.correct]: 'correct'
};

declare module '@mui/material/styles' {
  interface Palette {
    absent: Palette['primary'];
    present: Palette['primary'];
    correct: Palette['primary'];
  }

  interface PaletteOptions {
    absent?: PaletteOptions['primary'];
    present?: PaletteOptions['primary'];
    correct?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    absent: true;
    present: true;
    correct: true;
  }
}

const light = createTheme({
  palette: {
    primary: blueGrey,
    absent: {
      main: grey['500'],
      contrastText: '#fff'
    },
    present: {
      main: yellow['700'],
      contrastText: '#fff'
    },
    correct: {
      main: green['500'],
      contrastText: '#fff'
    }
  }
});

export const lightTheme = createTheme({
  ...light,
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: light.spacing(2),
          ':last-child': {
            padding: light.spacing(2)
          }
        }
      }
    }
  }
});
