/* tslint:disable */
/* eslint-disable */
/**
 * Cordial
 * A Wordle game you play with friends!
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateGameProps,
    CreateGamePropsFromJSON,
    CreateGamePropsToJSON,
    CreateGuessProps,
    CreateGuessPropsFromJSON,
    CreateGuessPropsToJSON,
    Game,
    GameFromJSON,
    GameToJSON,
} from '../models';

export interface CreateGameRequest {
    createGameProps?: CreateGameProps;
}

export interface CreateGuessRequest {
    id: number;
    createGuessProps?: CreateGuessProps;
}

export interface DeleteGameRequest {
    id: number;
}

export interface GetGameRequest {
    id: number;
}

/**
 * 
 */
export class GamesApi extends runtime.BaseAPI {

    /**
     * Creates a new game.
     */
    async createGameRaw(requestParameters: CreateGameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Game>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/games`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateGamePropsToJSON(requestParameters.createGameProps),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameFromJSON(jsonValue));
    }

    /**
     * Creates a new game.
     */
    async createGame(requestParameters: CreateGameRequest = {}, initOverrides?: RequestInit): Promise<Game> {
        const response = await this.createGameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Make a guess for the specified game.
     */
    async createGuessRaw(requestParameters: CreateGuessRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Game>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createGuess.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/games/{id}/guess`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateGuessPropsToJSON(requestParameters.createGuessProps),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameFromJSON(jsonValue));
    }

    /**
     * Make a guess for the specified game.
     */
    async createGuess(requestParameters: CreateGuessRequest, initOverrides?: RequestInit): Promise<Game> {
        const response = await this.createGuessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes the specified game.
     */
    async deleteGameRaw(requestParameters: DeleteGameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Game>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteGame.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/games/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameFromJSON(jsonValue));
    }

    /**
     * Deletes the specified game.
     */
    async deleteGame(requestParameters: DeleteGameRequest, initOverrides?: RequestInit): Promise<Game> {
        const response = await this.deleteGameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the specified game.
     */
    async getGameRaw(requestParameters: GetGameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Game>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getGame.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/games/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GameFromJSON(jsonValue));
    }

    /**
     * Returns the specified game.
     */
    async getGame(requestParameters: GetGameRequest, initOverrides?: RequestInit): Promise<Game> {
        const response = await this.getGameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all accessible games.
     */
    async listGamesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Game>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/games`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GameFromJSON));
    }

    /**
     * Returns all accessible games.
     */
    async listGames(initOverrides?: RequestInit): Promise<Array<Game>> {
        const response = await this.listGamesRaw(initOverrides);
        return await response.value();
    }

}
