/* tslint:disable */
/* eslint-disable */
/**
 * Cordial
 * A Wordle game you play with friends!
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Discardable,
    DiscardableFromJSON,
    DiscardableFromJSONTyped,
    DiscardableToJSON,
} from './Discardable';
import {
    Guess,
    GuessFromJSON,
    GuessFromJSONTyped,
    GuessToJSON,
} from './Guess';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Game
 */
export interface Game extends Discardable {
    /**
     * 
     * @type {string}
     * @memberof Game
     */
    word?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Game
     */
    requesterId?: string;
    /**
     * 
     * @type {User}
     * @memberof Game
     */
    requester?: User;
    /**
     * 
     * @type {string}
     * @memberof Game
     */
    acceptorId?: string;
    /**
     * 
     * @type {User}
     * @memberof Game
     */
    acceptor?: User;
    /**
     * 
     * @type {string}
     * @memberof Game
     */
    currentUserId?: string;
    /**
     * 
     * @type {User}
     * @memberof Game
     */
    currentUser?: User;
    /**
     * 
     * @type {boolean}
     * @memberof Game
     */
    isWon?: boolean | null;
    /**
     * 
     * @type {Array<Guess>}
     * @memberof Game
     */
    guesses?: Array<Guess>;
}

export function GameFromJSON(json: any): Game {
    return GameFromJSONTyped(json, false);
}

export function GameFromJSONTyped(json: any, ignoreDiscriminator: boolean): Game {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...DiscardableFromJSONTyped(json, ignoreDiscriminator),
        'word': !exists(json, 'word') ? undefined : json['word'],
        'requesterId': !exists(json, 'requesterId') ? undefined : json['requesterId'],
        'requester': !exists(json, 'requester') ? undefined : UserFromJSON(json['requester']),
        'acceptorId': !exists(json, 'acceptorId') ? undefined : json['acceptorId'],
        'acceptor': !exists(json, 'acceptor') ? undefined : UserFromJSON(json['acceptor']),
        'currentUserId': !exists(json, 'currentUserId') ? undefined : json['currentUserId'],
        'currentUser': !exists(json, 'currentUser') ? undefined : UserFromJSON(json['currentUser']),
        'isWon': !exists(json, 'isWon') ? undefined : json['isWon'],
        'guesses': !exists(json, 'guesses') ? undefined : ((json['guesses'] as Array<any>).map(GuessFromJSON)),
    };
}

export function GameToJSON(value?: Game | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...DiscardableToJSON(value),
        'word': value.word,
        'requesterId': value.requesterId,
        'requester': UserToJSON(value.requester),
        'acceptorId': value.acceptorId,
        'acceptor': UserToJSON(value.acceptor),
        'currentUserId': value.currentUserId,
        'currentUser': UserToJSON(value.currentUser),
        'isWon': value.isWon,
        'guesses': value.guesses === undefined ? undefined : ((value.guesses as Array<any>).map(GuessToJSON)),
    };
}

