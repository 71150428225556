/* tslint:disable */
/* eslint-disable */
/**
 * Cordial
 * A Wordle game you play with friends!
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
     ModelFromJSONTyped
} from './';

/**
 * 
 * @export
 * @interface BaseModel
 */
export interface BaseModel {
    /**
     * 
     * @type {string}
     * @memberof BaseModel
     */
    $type?: string;
    /**
     * 
     * @type {Date}
     * @memberof BaseModel
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BaseModel
     */
    updatedAt?: Date;
}

export function BaseModelFromJSON(json: any): BaseModel {
    return BaseModelFromJSONTyped(json, false);
}

export function BaseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['$type'] === 'Model') {
            return ModelFromJSONTyped(json, true);
        }
    }
    return {
        
        '$type': !exists(json, '$type') ? undefined : json['$type'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}

export function BaseModelToJSON(value?: BaseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '$type': value.$type,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}

