/* tslint:disable */
/* eslint-disable */
/**
 * Cordial
 * A Wordle game you play with friends!
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationsSubscribeKeys
 */
export interface NotificationsSubscribeKeys {
    /**
     * 
     * @type {string}
     * @memberof NotificationsSubscribeKeys
     */
    auth: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsSubscribeKeys
     */
    p256dh: string;
}

export function NotificationsSubscribeKeysFromJSON(json: any): NotificationsSubscribeKeys {
    return NotificationsSubscribeKeysFromJSONTyped(json, false);
}

export function NotificationsSubscribeKeysFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsSubscribeKeys {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'auth': json['auth'],
        'p256dh': json['p256dh'],
    };
}

export function NotificationsSubscribeKeysToJSON(value?: NotificationsSubscribeKeys | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auth': value.auth,
        'p256dh': value.p256dh,
    };
}

