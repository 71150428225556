import {
  Button,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogProps,
  Divider,
  Grid,
  GridProps,
  Typography
} from '@mui/material';
import { Game, GamesApi, Guess } from 'api';
import { DialogCloseTitle } from 'components/DialogCloseTitle';
import { Keyboard } from 'components/Keyboard';
import { ToolbarOffset } from 'components/ToolbarOffset';
import { Hide } from 'components/visibility/Hide';
import { Show } from 'components/visibility/Show';
import { apiConfig } from 'config';
import { phoneMediaQuery, useCurrentUser, useErrorHandler, useTrigger } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { comparisonColorMap } from 'themes';
import { abortEffect, delay, getPartner } from 'utils';

interface BoardProps extends GridProps {
  guesses: Guess[];
}

function Board(props: BoardProps): React.ReactElement {
  const { guesses, ...gridProps } = props;

  function renderRow(guess: Guess | null | undefined, index: number): React.ReactElement {
    const cells: React.ReactNodeArray = [];
    const word: string = guess?.word?.toUpperCase() || '';

    for (let i = 0; i < 5; i++) {
      const color = comparisonColorMap[(guess?.comparison || [])[i]];

      cells.push(
        <Grid
          item
          flexBasis={0}
          key={i}
          sx={{
            aspectRatio: '1 / 1',
            height: '100%'
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              height: '100%',
              width: '100%',
              borderStyle: 'solid',
              borderWidth: 2,
              borderColor: `${color || 'absent'}.main`,
              textAlign: 'center',
              fontSize: '5vh',
              lineHeight: 0,
              backgroundColor: `${color}.main`,
              color: `${color}.contrastText`
            }}
          >
            <Grid item flexBasis={0}>
              {word[i]}
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item key={index} sx={{ maxHeight: 72 }} flexGrow={1} flexShrink={1} flexBasis={0}>
        <Grid container spacing={1} className="h-100" flexWrap="nowrap" justifyContent="center" alignItems="stretch">
          {cells}
        </Grid>
      </Grid>
    );
  }

  const rows = [];

  for (let i = 0; i < 6; i++) {
    rows.push(renderRow(guesses[i], i));
  }

  return (
    <Grid container direction="column" justifyContent="center" {...gridProps}>
      {rows}
    </Grid>
  );
}

interface GameViewModalProps extends DialogProps {
  game: Game | null | undefined;
}

function GameViewDialog(props: GameViewModalProps): React.ReactElement {
  const { game, ...dialogProps } = props;
  const { onClose } = dialogProps;
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const partner = getPartner(game || {}, currentUser);
  const gamesApi = new GamesApi(apiConfig());

  let message = '';
  if (game?.isWon != null) {
    message = 'The Word Was';
  } else if (partner) {
    message = game?.currentUserId === currentUser.id ? 'Your Turn' : `${partner.displayName}'s Turn`;
  }

  let title: string | undefined = 'Solo Game';
  if (partner) {
    title = `Partner: ${partner.displayName}`;
  }

  function deleteGame(): void {
    handleError(async () => {
      if (game?.id) {
        await gamesApi.deleteGame({ id: game.id });
      }
      navigate('/');
    });
  }

  function startNewGame(): void {
    handleError(
      async () => {
        if (game?.id) {
          await gamesApi.deleteGame({ id: game.id });
        }
        const newGame = await gamesApi.createGame({
          createGameProps: { acceptorId: partner?.id || currentUser.id || '' }
        });
        navigate(`/games/${newGame.id}`);
      },
      { default: 'Error creating game' }
    );
  }

  return (
    <Dialog maxWidth="xs" fullWidth {...dialogProps}>
      <DialogCloseTitle onClose={onClose}>{title}</DialogCloseTitle>
      <Divider />
      <CardContent>
        <Typography variant="h5" textAlign="center">
          <Grid container direction="column" spacing={1} alignItems="center">
            <Show when={game?.isWon}>
              <Grid item>Congratulations!</Grid>
            </Show>
            <Grid item>{message}</Grid>
            <Hide when={game?.isWon == null}>
              <Grid item>
                <Typography variant="h4">{game?.word?.toUpperCase()}</Typography>
              </Grid>
            </Hide>
          </Grid>
        </Typography>
      </CardContent>
      <Hide when={game?.isWon == null && game?.currentUserId === currentUser.id}>
        <Divider />
        <DialogActions>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              {game?.isWon != null && game.currentUserId === currentUser.id ? (
                <Button variant="outlined" color="error" onClick={deleteGame}>
                  Delete Game
                </Button>
              ) : (
                <Button variant="outlined" onClick={() => navigate('/')}>
                  Back to Games
                </Button>
              )}
            </Grid>
            <Show when={game?.currentUserId === currentUser.id}>
              <Grid item>
                <Button variant="contained" onClick={startNewGame}>
                  Play Again
                </Button>
              </Grid>
            </Show>
          </Grid>
        </DialogActions>
      </Hide>
    </Dialog>
  );
}

export function GameView(): React.ReactElement {
  const [game, setGame] = useState<Game | null>();
  const [currentGuess, setCurrentGuess] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [dialogTrigger, triggerDialog] = useTrigger();
  const [reloadTrigger, triggerReload] = useTrigger();
  const currentUser = useCurrentUser();
  const handleError = useErrorHandler();
  const snackbar = useSnackbar();
  const params = useParams();
  const gamesApi = new GamesApi(apiConfig());
  const guesses = [...(game?.guesses || []), { word: currentGuess }];
  const delayMs = 1500;
  const reloadMs = 5000;

  useEffect(() => {
    return abortEffect((signal) => {
      handleError(
        async () => {
          const newGame = await gamesApi.getGame({ id: Number.parseInt(params.id as string) }, { signal });
          const partner = getPartner(newGame, currentUser);
          setGame(newGame);

          if (partner && newGame.isWon == null && newGame.currentUserId === currentUser.id) {
            snackbar.enqueueSnackbar('Your turn!', { variant: 'success' });
          } else {
            if (!firstLoad) {
              return;
            }
            await delay(delayMs);
            triggerDialog();
            setFirstLoad(false);
          }
        },
        {
          404: 'Game not found',
          default: 'Error retrieving game'
        }
      );
    });
  }, [params, reloadTrigger]);

  useEffect(() => {
    if (game?.currentUserId === currentUser.id) {
      // Only refresh if we're waiting for the other player
      return;
    }

    const timeout = setTimeout(() => {
      triggerReload();
    }, reloadMs);

    return () => {
      clearTimeout(timeout);
    };
  }, [game]);

  useEffect(() => {
    setDialogOpen(game != null && (game.isWon != null || game.currentUserId !== currentUser.id));
  }, [dialogTrigger]);

  function submitGuess(): void {
    handleError(
      async () => {
        if (game?.id == null) {
          return;
        }

        const newGame = await gamesApi.createGuess({ id: game.id, createGuessProps: { word: currentGuess } });
        setGame(newGame);
        setCurrentGuess('');
        await delay(delayMs);
        triggerDialog();
      },
      {
        default: { message: 'Word not found', variant: 'warning' }
      }
    );
  }

  function onKeyClick(key: string): void {
    switch (key) {
      case 'enter':
        if (game?.isWon != null) {
          setDialogOpen(true);
          break;
        }
        if (game?.currentUserId !== currentUser.id) {
          snackbar.enqueueSnackbar('It is not your turn', { preventDuplicate: true });
          break;
        }
        if (currentGuess.length !== 5) {
          snackbar.enqueueSnackbar('Word must be 5 letters', { preventDuplicate: true });
          break;
        }
        submitGuess();
        break;
      case 'backspace':
        setCurrentGuess(currentGuess.slice(0, -1));
        break;
      default:
        if (currentGuess.length >= 5) {
          break;
        }
        setCurrentGuess(currentGuess + key);
    }
  }

  return (
    <>
      <Container maxWidth="sm" sx={{ '&.MuiContainer-root': { height: '100%', p: 1, fontWeight: 'bold' } }}>
        <Grid container direction="column" className="h-100" flexWrap="nowrap">
          <Grid item flexShrink={0}>
            <ToolbarOffset />
          </Grid>
          <Grid item flexGrow={1} sx={{ pb: 1, pt: 1 }}>
            <Board guesses={guesses} className="h-100" />
          </Grid>
          <Grid item flexShrink={0} sx={{ height: 190, [phoneMediaQuery]: { height: 170 } }}>
            <Keyboard className="h-100" guesses={game?.guesses} onKeyClick={onKeyClick} />
          </Grid>
        </Grid>
      </Container>
      <GameViewDialog
        game={game}
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);

          if (game?.isWon != null) {
            snackbar.enqueueSnackbar('Press ENTER to see results', { preventDuplicate: true });
          }
        }}
      />
    </>
  );
}
