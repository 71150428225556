/* tslint:disable */
/* eslint-disable */
/**
 * Cordial
 * A Wordle game you play with friends!
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Discardable,
    DiscardableFromJSON,
    DiscardableFromJSONTyped,
    DiscardableToJSON,
} from './Discardable';

/**
 * 
 * @export
 * @interface Guess
 */
export interface Guess extends Discardable {
    /**
     * 
     * @type {string}
     * @memberof Guess
     */
    word?: string;
    /**
     * 
     * @type {number}
     * @memberof Guess
     */
    gameId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Guess
     */
    comparison?: Array<GuessComparisonEnum>;
}

export function GuessFromJSON(json: any): Guess {
    return GuessFromJSONTyped(json, false);
}

export function GuessFromJSONTyped(json: any, ignoreDiscriminator: boolean): Guess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...DiscardableFromJSONTyped(json, ignoreDiscriminator),
        'word': !exists(json, 'word') ? undefined : json['word'],
        'gameId': !exists(json, 'gameId') ? undefined : json['gameId'],
        'comparison': !exists(json, 'comparison') ? undefined : json['comparison'],
    };
}

export function GuessToJSON(value?: Guess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...DiscardableToJSON(value),
        'word': value.word,
        'gameId': value.gameId,
        'comparison': value.comparison,
    };
}

