import { AccountCircle, Close, GridView, Logout, Menu as MenuIcon, Replay } from '@mui/icons-material';
import {
  AppBar,
  Button,
  Drawer,
  GlobalStyles,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  ThemeProvider,
  Toolbar,
  Typography
} from '@mui/material';
import { AuthApi, NotificationsApi, UsersApi } from 'api';
import { InstallNativeDialog } from 'components/InstallNativeDialog';
import { ListItemLink } from 'components/ListItemLink';
import { PaddedBox } from 'components/PaddedBox';
import { createNotificationSubscription } from 'components/RequestNotificationsDialog';
import { ToolbarOffset } from 'components/ToolbarOffset';
import { AccountView } from 'components/views/AccountView';
import { CreateAccountView } from 'components/views/CreateAccountView';
import { GamesListView } from 'components/views/GamesListView';
import { GameView } from 'components/views/GameView';
import { LoginView } from 'components/views/LoginView';
import { NotFoundView } from 'components/views/NotFoundView';
import { PrivacyPolicyView } from 'components/views/PrivacyPolicyView';
import { SupportView } from 'components/views/SupportView';
import { UserView } from 'components/views/UserView';
import { Show } from 'components/visibility/Show';
import { apiConfig, getNativeAppRejected } from 'config';
import {
  CurrentUserProvider,
  useAccessTokenState,
  useCurrentUserState,
  useErrorHandler,
  useIsMobileSize,
  useTrigger
} from 'hooks';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { BrowserRouter, createSearchParams, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { lightTheme } from 'themes';
import { abortEffect, getSubscription, nativeAppAvailable } from 'utils';

function AppWrapper({ onLogout }: { onLogout: () => void }): React.ReactElement {
  const isMobileSize = useIsMobileSize();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);
  const drawerWidth = 240;

  useEffect(() => {
    if (isMobileSize) {
      setDrawerOpen(false);
    }
  }, [isMobileSize, location]);

  return (
    <>
      <AppBar sx={(theme: Theme) => ({ zIndex: theme.zIndex.drawer + 1 })}>
        <Toolbar>
          <Show when={isMobileSize}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setDrawerOpen(!drawerOpen)}
            >
              <MenuIcon />
            </IconButton>
          </Show>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Cordial
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container className="h-100">
        <Grid item flexShrink={0}>
          <Drawer
            variant={isMobileSize ? 'temporary' : 'permanent'}
            open={drawerOpen}
            ModalProps={{ keepMounted: true }}
            onClose={() => setDrawerOpen(false)}
            sx={{
              width: drawerWidth,
              '& .MuiDrawer-paper': {
                width: drawerWidth
              }
            }}
          >
            <ToolbarOffset />
            <List>
              <ListItemLink to="/">
                <ListItemIcon>
                  <GridView />
                </ListItemIcon>
                <ListItemText primary="Games" />
              </ListItemLink>
              <ListItemLink to="/account">
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary="Account" />
              </ListItemLink>
              {/*<ListItemLink to="/how-to-play">*/}
              {/*  <ListItemIcon>*/}
              {/*    <Help />*/}
              {/*  </ListItemIcon>*/}
              {/*  <ListItemText primary="How to Play" />*/}
              {/*</ListItemLink>*/}
              <ListItem button onClick={onLogout}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </Drawer>
        </Grid>
        <Grid item flexGrow={1} className="h-100">
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
}

function ContentWrapper(): React.ReactElement {
  return (
    <PaddedBox>
      <ToolbarOffset />
      <Outlet />
    </PaddedBox>
  );
}

const nextLocationParam = 'nextLocation';

function AppRoutes(): React.ReactElement {
  const [loading, setLoading] = useState(true);
  const [serviceWorkerReady, setServiceWorkerReady] = useState(false);
  const [token, setToken] = useAccessTokenState();
  const [currentUser, setCurrentUser] = useCurrentUserState();
  const [reloadTrigger, triggerReload] = useTrigger();
  const navigate = useNavigate();
  const location = useLocation();
  const handleError = useErrorHandler();
  const authApi = new AuthApi(apiConfig());
  const notificationsApi = new NotificationsApi(apiConfig());

  useEffect(() => {
    (async () => {
      // Skip if the browser doesn't support service workers or if one isn't being used
      if (!('serviceWorker' in navigator) || navigator.serviceWorker.controller == null) {
        return;
      }

      // Check for a service worker update whenever the location changes
      try {
        const registration = await navigator.serviceWorker.ready;
        await registration.update();
        const newWorker = registration.installing || registration.waiting;

        if (newWorker) {
          await new Promise((_, reject) => {
            newWorker.addEventListener('statechange', () => {
              if (newWorker.state === 'activated') {
                window.location.reload();
              } else if (newWorker.state === 'redundant') {
                reject(new Error('Failed to install service worker'));
              }
            });
            newWorker.postMessage({ type: 'SKIP_WAITING' });
          });
        }
      } catch (e) {
        console.error(e);
      }
    })().then(() => setServiceWorkerReady(true));
  }, [location]);

  useEffect(() => {
    setLoading(true);

    if (!serviceWorkerReady) {
      return;
    }

    if (token == null) {
      if (!['/login', '/create-account', '/privacy', '/support'].includes(location.pathname)) {
        const currentLocation = location.pathname + location.search + location.hash;
        const search = currentLocation === '/' ? '' : `?${nextLocationParam}=${encodeURIComponent(currentLocation)}`;
        navigate(`/login${search}`, { replace: true });
      }

      setLoading(false);
      return;
    }

    return abortEffect(async (signal) => {
      await createNotificationSubscription(signal).catch();
      await handleError(
        async () => {
          setCurrentUser(await new UsersApi(apiConfig()).getMyAccount({ signal }));
          setLoading(false);
          if (location.pathname === '/login') {
            const nextLocation = createSearchParams(location.search).get(nextLocationParam);
            navigate(nextLocation ? nextLocation : '/', { replace: true });
          }
        },
        {
          401: () => setToken(undefined),
          default: (snackbar) =>
            snackbar.enqueueSnackbar('Unable to reach server', {
              variant: 'error',
              autoHideDuration: null,
              action: (key: SnackbarKey) => (
                <IconButton
                  color="inherit"
                  onClick={() => {
                    snackbar.closeSnackbar(key);
                    triggerReload();
                  }}
                >
                  <Replay />
                </IconButton>
              )
            })
        }
      );
    });
  }, [token, serviceWorkerReady, reloadTrigger]);

  if (loading) {
    return <></>;
  }

  async function logout(): Promise<void> {
    handleError(
      async () => {
        // Attempt to unsubscribe then logout
        const sub = await getSubscription();
        const endpoint = sub?.toJSON()?.endpoint;

        if (endpoint) {
          // We don't care if the API unsubscribe fails
          // It will automatically be deleted by the API when it realizes the endpoint no longer works
          await notificationsApi.unsubscribe({ unsubscribeProps: { endpoint } }).catch();
          await sub.unsubscribe();
        }

        await authApi.logout();
        navigate('/login');
        setToken(undefined);
      },
      { default: 'Error logging out' }
    );
  }

  return (
    <Routes>
      <Route path="/login" element={<LoginView onLogin={setToken} />} />
      <Route path="/create-account" element={<CreateAccountView />} />
      <Route path="/privacy" element={<PrivacyPolicyView />} />
      <Route path="/support" element={<SupportView />} />
      <Route path="/" element={<AppWrapper onLogout={logout} />}>
        <Route path="/games">
          <Route path=":id" element={<GameView />} />
        </Route>
        <Route path="" element={<ContentWrapper />}>
          <Route path="" element={<GamesListView />} />
          <Route path="/users/:id" element={<UserView />} />
          <Route path="/account" element={<AccountView />} />
          {/*<Route path="/how-to-play" element={<HowToPlayView />} />*/}
        </Route>
      </Route>
      <Route path="*" element={<NotFoundView />} />
    </Routes>
  );
}

export function AppSnackbarProvider(): React.ReactElement {
  const [dialogOpen, setDialogOpen] = useState(false);
  const isMobileSize = useIsMobileSize();
  const outerSnackbarRef = React.createRef<SnackbarProvider>();
  const snackbarRef = React.createRef<SnackbarProvider>();
  const dismissOuter = (key: SnackbarKey): void => {
    outerSnackbarRef.current?.closeSnackbar(key);
  };
  const dismiss = (key: SnackbarKey): void => {
    snackbarRef.current?.closeSnackbar(key);
  };

  useEffect(() => {
    const snackbar = outerSnackbarRef.current;

    if (getNativeAppRejected() || !nativeAppAvailable || snackbar == null) {
      return;
    }

    snackbar.enqueueSnackbar(`${isIOS ? 'iOS' : 'Android'} App Available!`, { persist: true });
  }, []);

  return (
    <>
      <GlobalStyles
        styles={(theme) => ({
          '.SnackbarItem-wrappedRoot.primary': {
            '.SnackbarContent-root': {
              backgroundColor: theme.palette.primary.main
            }
          }
        })}
      />
      <SnackbarProvider
        classes={{ root: 'primary' }}
        maxSnack={1}
        dense={isMobileSize}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        ref={outerSnackbarRef}
        action={(key) => (
          <>
            <Button
              color="inherit"
              onClick={() => {
                dismissOuter(key);
                setDialogOpen(true);
              }}
            >
              Details
            </Button>
            <IconButton color="inherit" onClick={() => dismissOuter(key)}>
              <Close />
            </IconButton>
          </>
        )}
      >
        <SnackbarProvider
          maxSnack={1}
          dense={isMobileSize}
          variant="info"
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          ref={snackbarRef}
          action={(key) => (
            <IconButton color="inherit" onClick={() => dismiss(key)}>
              <Close />
            </IconButton>
          )}
        >
          <CurrentUserProvider>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </CurrentUserProvider>
        </SnackbarProvider>
      </SnackbarProvider>
      <InstallNativeDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
    </>
  );
}

export function AppView(): React.ReactElement {
  return (
    <ThemeProvider theme={lightTheme}>
      <AppSnackbarProvider />
    </ThemeProvider>
  );
}
