import { Container, ContainerProps, styled } from '@mui/material';
import React from 'react';

const StyledContainer = styled(Container)<ContainerProps>({
  '&.MuiContainer-root': {
    padding: 0
  }
});

export function AppContainer(props: ContainerProps): React.ReactElement {
  return <StyledContainer maxWidth="sm" {...props} />;
}
