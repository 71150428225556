import { Configuration, ConfigurationParameters } from 'api';

const tokenKey = 'cordial-access-token';
const rejectedNativeAppKey = 'native-rejected';

export function getToken(): string | undefined {
  return localStorage.getItem(tokenKey) || undefined;
}

export function setToken(token?: string | null): void {
  if (token == null) {
    localStorage.removeItem(tokenKey);
  } else {
    localStorage.setItem(tokenKey, token);
  }
}

export function getNativeAppRejected(): boolean {
  return localStorage.getItem(rejectedNativeAppKey) === 'true';
}

export function setNativeAppRejected(token: boolean | null | undefined): void {
  if (token == null) {
    localStorage.removeItem(rejectedNativeAppKey);
  } else {
    localStorage.setItem(rejectedNativeAppKey, token.toString());
  }
}

export const baseConfig: ConfigurationParameters = {};

export function apiConfig(): Configuration {
  return new Configuration({
    basePath: process.env.REACT_APP_API_URL || 'https://cordial-api.joshraker.com',
    accessToken: getToken(),
    ...baseConfig
  });
}

export const notificationsRequestedKey = 'notifications-requested';

export function getNotificationsRequested(): boolean | null {
  switch (localStorage.getItem(notificationsRequestedKey)) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
}

export function setNotificationsRequested(value: boolean | null): void {
  if (value == null) {
    localStorage.removeItem(notificationsRequestedKey);
  } else {
    localStorage.setItem(notificationsRequestedKey, value ? 'true' : 'false');
  }
}
