/* tslint:disable */
/* eslint-disable */
/**
 * Cordial
 * A Wordle game you play with friends!
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateGuessProps
 */
export interface CreateGuessProps {
    /**
     * 
     * @type {string}
     * @memberof CreateGuessProps
     */
    word: string;
}

export function CreateGuessPropsFromJSON(json: any): CreateGuessProps {
    return CreateGuessPropsFromJSONTyped(json, false);
}

export function CreateGuessPropsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateGuessProps {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'word': json['word'],
    };
}

export function CreateGuessPropsToJSON(value?: CreateGuessProps | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'word': value.word,
    };
}

