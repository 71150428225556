import { ContentCopy, DeleteForever, People, Person } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { Game, GamesApi } from 'api';
import { AppContainer } from 'components/AppContainer';
import { DialogCloseTitle } from 'components/DialogCloseTitle';
import { RequestNotificationsDialog } from 'components/RequestNotificationsDialog';
import { Show } from 'components/visibility/Show';
import { apiConfig, getNotificationsRequested } from 'config';
import { useCurrentUser, useErrorHandler, useTrigger } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { abortEffect, closeDialog, getPartner, notificationsSupported } from 'utils';

function PlayWithFriendsDialog(dialogProps: DialogProps): React.ReactElement {
  const { onClose } = dialogProps;
  const currentUser = useCurrentUser();
  const snackbar = useSnackbar();

  return (
    <Dialog maxWidth="sm" {...dialogProps}>
      <DialogCloseTitle onClose={onClose}>Play With Friends</DialogCloseTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            Click the button below to copy the link to your profile to the clipboard. Others can use this link to start
            games with you!
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              fullWidth
              size="large"
              onClick={async () => {
                await navigator.clipboard.writeText(`${location.origin}/users/${currentUser.id}`);
                snackbar.enqueueSnackbar('Link copied to clipboard!', { variant: 'success' });
                closeDialog(onClose);
              }}
            >
              <ContentCopy sx={{ mr: 1 }} /> Copy Link
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export interface DeleteGameDialogProps extends DialogProps {
  game: Game | null | undefined;
  onDelete?: () => void;
}

function DeleteGameDialog(props: DeleteGameDialogProps): React.ReactElement {
  const { game, onDelete, ...dialogProps } = props;
  const { onClose } = dialogProps;
  const currentUser = useCurrentUser();
  const handleError = useErrorHandler();
  const snackbar = useSnackbar();
  const gamesApi = new GamesApi(apiConfig());
  const partner = getPartner(game || {}, currentUser);

  function deleteGame(): void {
    handleError(async () => {
      if (game?.id == null) {
        return;
      }

      await gamesApi.deleteGame({ id: game.id });
      snackbar.enqueueSnackbar('Game deleted', { variant: 'success' });
      onDelete && onDelete();
      closeDialog(onClose);
    });
  }

  return (
    <Dialog maxWidth="sm" {...dialogProps}>
      <DialogCloseTitle onClose={onClose}>Delete Game</DialogCloseTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            Are you sure you want to delete this{' '}
            {partner ? (
              <>
                game with <b>{partner.displayName}</b>
              </>
            ) : (
              'solo game'
            )}
            ?
          </Grid>
          <Show when={partner && game?.isWon != null && game.currentUserId === partner.id}>
            <Grid item>
              Your partner may not have seen the results of the game. They will be prompted to delete the game once they
              have.
            </Grid>
          </Show>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" color="error" fullWidth size="large" onClick={deleteGame}>
          <DeleteForever sx={{ mr: 1 }} /> Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function GamesListView(): React.ReactElement {
  const [allGames, setAllGames] = useState<Game[] | null>();
  const [yourTurnGames, setYourTurnGames] = useState<Game[]>([]);
  const [partnersTurnGames, setPartnersTurnGames] = useState<Game[]>([]);
  const [playWithOthersDialog, setPlayWithOthersDialog] = useState(false);
  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);
  const [gameToDelete, setGameToDelete] = useState<Game | null>();
  const [reloadTrigger, triggerReload] = useTrigger();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const gamesApi = new GamesApi(apiConfig());
  const gameTitle = useCallback(
    (game: Game) => getPartner(game, currentUser)?.displayName || 'Solo Game',
    [currentUser]
  );
  const startSoloGame = useCallback(async () => {
    if (currentUser.id == null) {
      return;
    }

    const game = await gamesApi.createGame({ createGameProps: { acceptorId: currentUser.id } });
    navigate(`/games/${game.id}`);
  }, [currentUser]);

  function gameStatus(game: Game): string {
    const turn = game.guesses?.length || 0;

    if (game.isWon == null) {
      return `Turn ${turn} / 6`;
    } else {
      return 'Complete';
    }
  }

  function renderGames(games: Game[] | null): React.ReactElement {
    if (!games?.length) {
      return (
        <Typography variant="h4" textAlign="center">
          None
        </Typography>
      );
    }

    return (
      <Grid container direction="column" spacing={2}>
        {games?.map((game) => (
          <Grid item key={game.id}>
            <Card elevation={5}>
              <CardActionArea component={Link} to={`/games/${game.id}`}>
                <CardContent>
                  <Typography variant="h6">
                    <Grid container spacing={2} alignItems="center">
                      <Grid item flexGrow={1}>
                        {gameTitle(game)}
                      </Grid>
                      <Grid item>{gameStatus(game)}</Grid>
                      <Grid item>
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            setGameToDelete(game);
                          }}
                        >
                          <DeleteForever />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }

  useEffect(() => {
    (async () => {
      // Check if notifications are supported
      if (!(await notificationsSupported())) {
        return;
      }

      // Don't ask about notifications unless there are some games with others
      if (!allGames?.some((game) => game.requesterId !== game.acceptorId)) {
        return;
      }

      const notificationsRequested = getNotificationsRequested();

      // If the user has not indicated if they want to receive notifications or
      // if they do want notifications but the permission has been reset, ask them if they want notifications
      if (notificationsRequested == null || (notificationsRequested && Notification.permission === 'default')) {
        setNotificationDialogOpen(true);
        return;
      }
    })();
  }, [allGames]);

  useEffect(() => {
    return abortEffect(async (signal) => {
      setAllGames(await gamesApi.listGames({ signal }));
    });
  }, [reloadTrigger]);

  useEffect(() => {
    if (allGames == null) {
      return;
    }

    const yourTurn: Game[] = [];
    const theirTurn: Game[] = [];

    for (const game of allGames) {
      if (game.currentUserId === currentUser.id) {
        yourTurn.push(game);
      } else {
        theirTurn.push(game);
      }
    }

    setYourTurnGames(yourTurn);
    setPartnersTurnGames(theirTurn);
  }, [allGames]);

  return (
    <AppContainer>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h2" align="center">
            Games
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" fullWidth size="large" onClick={() => setPlayWithOthersDialog(true)}>
                <People sx={{ mr: 1 }} /> Play With Friends
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" fullWidth size="large" onClick={startSoloGame}>
                <Person sx={{ mr: 1 }} /> Start Solo Game
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="h3" align="center">
            Your Turn
          </Typography>
        </Grid>
        <Grid item>{renderGames(yourTurnGames)}</Grid>
        <Grid item>
          <Typography variant="h3" align="center">
            Partner&apos;s Turn
          </Typography>
        </Grid>
        <Grid item>{renderGames(partnersTurnGames)}</Grid>
      </Grid>
      <PlayWithFriendsDialog open={playWithOthersDialog} onClose={() => setPlayWithOthersDialog(false)} />
      <DeleteGameDialog
        game={gameToDelete}
        open={gameToDelete != null}
        onDelete={triggerReload}
        onClose={() => setGameToDelete(null)}
      />
      <RequestNotificationsDialog open={notificationDialogOpen} onClose={() => setNotificationDialogOpen(false)} />
    </AppContainer>
  );
}
