/* tslint:disable */
/* eslint-disable */
/**
 * Cordial
 * A Wordle game you play with friends!
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUserProps
 */
export interface CreateUserProps {
    /**
     * 
     * @type {string}
     * @memberof CreateUserProps
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserProps
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserProps
     */
    displayName: string;
}

export function CreateUserPropsFromJSON(json: any): CreateUserProps {
    return CreateUserPropsFromJSONTyped(json, false);
}

export function CreateUserPropsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserProps {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
        'displayName': json['displayName'],
    };
}

export function CreateUserPropsToJSON(value?: CreateUserProps | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'displayName': value.displayName,
    };
}

