import { Close } from '@mui/icons-material';
import { DialogTitle, DialogTitleProps, Grid, IconButton } from '@mui/material';
import React from 'react';
import { closeDialog } from 'utils';

export interface DialogCloseTitleProps extends DialogTitleProps {
  onClose?: DialogCloseFunction;
}

export function DialogCloseTitle(props: DialogCloseTitleProps): React.ReactElement {
  const { onClose, children, ...titleProps } = props;

  return (
    <DialogTitle {...titleProps}>
      <Grid container justifyContent="space-between" alignItems="center" flexWrap="nowrap">
        <Grid item minWidth={0} overflow="scroll">
          {children}
        </Grid>
        <Grid item>
          <IconButton onClick={() => closeDialog(onClose)}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
  );
}
