import { Button, Dialog, DialogActions, DialogContent, DialogProps, Divider, Grid } from '@mui/material';
import { NotificationsApi } from 'api';
import { DialogCloseTitle } from 'components/DialogCloseTitle';
import { apiConfig, getNotificationsRequested, setNotificationsRequested } from 'config';
import { ErrorHandler, useErrorHandler } from 'hooks';
import { ProviderContext, useSnackbar } from 'notistack';
import React from 'react';
import { closeDialog, getSubscription, notificationsSupported } from 'utils';

export async function createNotificationSubscription(signal?: AbortSignal): Promise<void> {
  if (!((await notificationsSupported()) && getNotificationsRequested())) {
    return;
  }

  const registration = await navigator.serviceWorker.getRegistration();

  if (registration == null) {
    return;
  }

  const notificationsApi = new NotificationsApi(apiConfig());
  const vapidKey = (await notificationsApi.getPublicKey({ signal })).publicKey;
  const sub = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: vapidKey
  });

  await notificationsApi.subscribe({ subscription: sub.toJSON() as any });
}

export async function enableNotifications(snackbar: ProviderContext, handleError: ErrorHandler): Promise<boolean> {
  const result = await handleError(
    async () => {
      if (await notificationsSupported()) {
        if ((await Notification.requestPermission()) !== 'granted') {
          snackbar.enqueueSnackbar('Notifications permission is required', { variant: 'error' });
          return;
        }

        await createNotificationSubscription();
      }

      setNotificationsRequested(true);
      snackbar.enqueueSnackbar('Notifications enabled!', { variant: 'success' });
      return true;
    },
    { default: 'Error enabling notifications' }
  );

  return result || false;
}

export async function disableNotifications(): Promise<void> {
  setNotificationsRequested(false);

  const notificationsApi = new NotificationsApi(apiConfig());
  const sub = await getSubscription();
  const endpoint = sub?.toJSON()?.endpoint;

  if (endpoint) {
    await notificationsApi.unsubscribe({ unsubscribeProps: { endpoint } }).catch();
    await sub.unsubscribe();
  }
}

export function RequestNotificationsDialog(dialogProps: DialogProps): React.ReactElement {
  const { onClose } = dialogProps;
  const snackbar = useSnackbar();
  const handleError = useErrorHandler();

  return (
    <Dialog maxWidth="sm" {...dialogProps}>
      <DialogCloseTitle onClose={onClose}>Notifications</DialogCloseTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="column" spacing={1} alignItems="center" textAlign="center">
          <Grid item>Would you like to receive notifications on this device?</Grid>
          <Grid item>(This can be changed from the Account page at any time)</Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              onClick={async () => {
                await disableNotifications().catch();
                closeDialog(onClose);
              }}
            >
              No
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={async () => {
                if (await enableNotifications(snackbar, handleError)) {
                  closeDialog(onClose);
                }
              }}
            >
              Yes
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
