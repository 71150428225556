/* tslint:disable */
/* eslint-disable */
/**
 * Cordial
 * A Wordle game you play with friends!
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Discardable,
    DiscardableFromJSON,
    DiscardableFromJSONTyped,
    DiscardableToJSON,
} from './Discardable';

/**
 * 
 * @export
 * @interface Token
 */
export interface Token extends Discardable {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    accessToken?: string;
}

export function TokenFromJSON(json: any): Token {
    return TokenFromJSONTyped(json, false);
}

export function TokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): Token {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...DiscardableFromJSONTyped(json, ignoreDiscriminator),
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
    };
}

export function TokenToJSON(value?: Token | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...DiscardableToJSON(value),
        'accessToken': value.accessToken,
    };
}

